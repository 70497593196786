<template>
  <div class="flex-container non-scrollable">
    <div v-if="companySettings" class="scrollable">
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid p-5">
        <span class="page-sub-title">{{ $t('admin.params.rh.creditCardTitle') }}</span>

        <div class="flex flex-column mb-5 mt-3">
          <div class="flex flex-row align-items-center mb-2">
            <Checkbox id="rhCreditCardOption" v-model="rhCreditCardOption" class="mr-2" :binary="true" @change="changeCreditCardOption"/>
            <label for="rhCreditCardOption">{{ $t('admin.params.rh.creditCardOption') }}</label>
          </div>
          <div v-if="rhCreditCardOption" class="flex flex-row align-items-center">
            <label for="rhCreditCardFees" class="w-2 block text-900 font-medium"> {{ $t('admin.params.rh.creditCardOptionFees') }}</label>
            <InputNumber id="rhCreditCardFees" v-model="rhCreditCardFees" class="w-3" mode="decimal" highlightOnFocus
                         :minFractionDigits="2" :maxFractionDigits="2" suffix=" € / An" />
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.rh.restaurantTicketsTitle') }}</span>

        <div class="flex flex-column mb-5 mt-3">
          <div class="flex flex-row align-items-center mt-2 mb-2">
            <Checkbox id="rhRestaurantsTicketsOption" v-model="rhRestaurantsTicketsOption" class="mr-2" :binary="true" @change="changeRestaurantsTicketsOption" />
            <label for="rhRestaurantsTicketsOption">{{ $t('admin.params.rh.restaurantsTicketsOption') }}</label>
          </div>
          <div v-if="rhRestaurantsTicketsOption" class="flex flex-row align-items-center mb-2">
            <label for="rhRestaurantsTicketsAmount" class="w-2 block text-900 font-medium"> {{ $t('admin.params.rh.restaurantsTicketsAmount') }}</label>
            <InputNumber id="rhRestaurantsTicketsAmount" v-model="rhRestaurantsTicketsAmount" class="w-3" mode="decimal"
                         :minFractionDigits="2" :maxFractionDigits="2" suffix=" €" highlightOnFocus />
          </div>
          <div v-if="rhRestaurantsTicketsOption" class="flex flex-row align-items-center mb-2">
            <label for="rhRestaurantsTicketsSalaryRate" class="w-2 block text-900 font-medium"> {{ $t('admin.params.rh.rhRestaurantsTicketsSalaryRate') }}</label>
            <InputNumber id="rhRestaurantsTicketsSalaryRate" v-model="rhRestaurantTicketsSalaryRate" class="w-3" highlightOnFocus
                         mode="decimal" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" suffix=" %" @input="changeTRSalaryRate"/>
          </div>
          <div v-if="rhRestaurantsTicketsOption" class="flex flex-row align-items-center">
            <label for="rhRestaurantsTicketsEmployerRate" class="w-2 block text-900 font-medium"> {{ $t('admin.params.rh.rhRestaurantsTicketsEmployerRate') }}</label>
            <InputNumber id="rhRestaurantsTicketsEmployerRate" v-model="rhRestaurantTicketsEmployerRate" class="w-3" highlightOnFocus
                         mode="decimal" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" suffix=" %" @input="changeTREmployerRate"/>
          </div>
<!--          Auto-generated letter-->
<!--          <div class="flex flex-row align-items-center mt-3 mb-2">-->
<!--            <Checkbox id="rhRenunciationLetter" v-model="rhRenunciationLetter" class="mr-2" :binary="true" />-->
<!--            <label for="rhRenunciationLetter">{{ $t('admin.params.rh.breakLetterOption') }}</label>-->
<!--          </div>-->
        </div>

        <span class="page-sub-title">{{ $t('admin.params.rh.contractTitle') }}</span>
        <div class="flex flex-column mb-2 mt-3">
          <table class="w-6">
            <thead>
            <tr>
              <th class="w-8"></th>
              <th class="w-1">Oui</th>
              <th class="w-1">Non</th>
              <th class="w-1"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="py-2">
                <span class="font-bold w-4 mr-4">Souhaitez-vous utiliser le modèle des
                  <a class="font-medium underline clickable-link text-primary" v-tooltip.top="'Voir le modèle des conditions particulères'" @click="openParticularConditionsPreview">
                    conditions particulières du contrat de travail de Kaizen
                  </a>
                  ?
                </span>
              </td>
              <td class="text-center">
                <RadioButton v-model="rhTemplateWorkContractParticularsConditions" :value="true" @change="openWorkContractParticularConditionsAcceptationPreview"/>
              </td>
              <td class="text-center">
                <RadioButton v-model="rhTemplateWorkContractParticularsConditions" :value="false" @change="saveContract"/>
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="py-2">
                <span class="font-bold w-4 mr-4">
                  Souhaitez-vous utiliser le modèle des
                  <a class="font-medium underline clickable-link text-primary" v-tooltip.top="'Voir le modèle des conditions générales'" @click="openGeneralConditionsPreview">
                    conditions générales de contrat de Kaizen
                  </a>
                  ? <span class="font-medium">(Si non, importez vos propres conditions générales)</span>
                </span>
              </td>
              <td class="text-center">
                <RadioButton v-model="rhTemplateWorkContractGeneralConditions" :value="true" @change="openWorkContractGeneralConditionAcceptationPreview"/>
              </td>
              <td class="text-center">
                <RadioButton v-model="rhTemplateWorkContractGeneralConditions" :value="false" @change="saveContract"/>
              </td>
              <td>
<!--                <FileUpload-->
<!--                    v-if="!rhTemplateWorkContractGeneralConditions && (rhOwnGeneralConditionsTemplateId === '' || !rhOwnGeneralConditionsTemplateId)"-->
<!--                    id="rhTemplateWorkContractOwnGeneralConditionUpload"-->
<!--                    class="custom-upload"-->
<!--                    ref="logo"-->
<!--                    mode="basic"-->
<!--                    accept="application/pdf"-->
<!--                    uploadIcon="pi pi-upload"-->
<!--                    choose-label=" "-->
<!--                    :showUploadButton="false"-->
<!--                    :maxFileSize="10000000"-->
<!--                    auto-->
<!--                    custom-upload-->
<!--                    @uploader="onUploadOwnGeneralCondition"-->
<!--                />-->
<!--                <Button v-else-if="!rhTemplateWorkContractGeneralConditions" icon="pi pi-eye" class="p-button-rounded p-button-text" @click="openOwnGeneralCondition"/>-->
                <Button
                  v-if="!rhTemplateWorkContractGeneralConditions"
                  :icon="(rhOwnGeneralConditionsTemplateId === '' || !rhOwnGeneralConditionsTemplateId) ? 'pi pi-upload' : 'pi pi-eye'"
                  class="p-button-rounded p-button-text"
                  @click="openOwnGeneralCondition"
                />
              </td>
            </tr>
            <tr>
              <td class="py-2">
                <span class="font-bold w-4 mr-4">
                  Souhaitez-vous importer votre convention d'adhésion au portage salarial à votre contrat de travail ?
                  <!--                  <a class="font-medium underline clickable-link text-primary" :class="''" v-tooltip.top="'Voir votre convention d\'adhésion'" @click="openOwnAgreement">-->
                  <!--                    convention d'adhésion au portage salarial à votre contrat de travail ?-->
                  <!--                  </a>-->
                </span>
              </td>
              <td class="text-center">
                <RadioButton v-model="rhAgreementConvention" :value="true" @change="openOwnAgreement"/>
              </td>
              <td class="text-center">
                <RadioButton v-model="rhAgreementConvention" :value="false" @change="saveContract"/>
              </td>
              <td>
<!--                <FileUpload-->
<!--                    v-if="rhAgreementConvention && (rhAgreementConventionId === '' || !rhAgreementConventionId)"-->
<!--                    id="rhAgreementConvention"-->
<!--                    class="custom-upload"-->
<!--                    ref="logo"-->
<!--                    mode="basic"-->
<!--                    accept="application/pdf"-->
<!--                    uploadIcon="pi pi-upload"-->
<!--                    choose-label=" "-->
<!--                    :showUploadButton="false"-->
<!--                    :maxFileSize="10000000"-->
<!--                    auto-->
<!--                    custom-upload-->
<!--                    @uploader="onUploadAgreementConvention"-->
<!--                />-->
<!--                <Button v-else-if="rhAgreementConvention" icon="pi pi-eye" class="p-button-rounded p-button-text" @click="openOwnAgreement"/>-->
                <Button
                  v-if="rhAgreementConvention"
                  :icon="(rhAgreementConventionId === '' || !rhAgreementConventionId) ? 'pi pi-upload' : 'pi pi-eye'"
                  class="p-button-rounded p-button-text"
                  @click="openOwnAgreement"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="flex flex-row align-items-center mb-2 mt-5">
          <span style="font-weight: bold;">{{ $t('admin.params.rh.boxForesightTitle') + ' : ' }}</span>
        </div>

        <div class="flex flex-row align-items-center mb-2 mt-3">
          <label for="rhRetirement" class="w-2">{{ $t('admin.params.rh.retirement') + ' : '}}</label>
          <InputText id="rhRetirement" class="w-3" v-model="rhRetirement" />
        </div>

        <div class="flex flex-row align-items-center mb-2 mt-3">
          <label for="rhForesight" class="w-2">{{ $t('admin.params.rh.foresight') + ' : '}}</label>
          <InputText id="rhForesight" class="w-3" v-model="rhForesight" />
        </div>

        <div v-if="companySettings.convention === constants.COLLECTIVE_CONVENTION.SYNTEC">
          <span class="page-sub-title">{{ $t('admin.params.rh.salaryGridTitle') }}</span>
          <div class="card w-8 mt-3">
            <DataTable
                ref="dt" :value="salaryGrid" v-model:selection="selectedSalaryLines" dataKey="id"
                responsiveLayout="scroll"
            >
              <Column selectionMode="multiple" :exportable="false"></Column>
              <Column field="position" header="Position" :sortable="true">
                <template #body="slotProps">
                  <InputText v-model="slotProps.data.position" placeholder="Position" />
                </template>
              </Column>
              <Column field="coefficient" header="Coefficient" :sortable="true">
                <template #body="slotProps">
                  <InputNumber v-model="slotProps.data.coefficient" placeholder="Coefficient" highlightOnFocus/>
                </template>
              </Column>
              <Column field="minimumBrutSalary" header="Salaire Minimum Brut" :sortable="true">
                <template #body="slotProps">
                  <InputNumber v-model="slotProps.data.minimumBrutSalary" mode="decimal" highlightOnFocus
                               :minFractionDigits="2" :maxFractionDigits="2" suffix=" €" placeholder="Salaire Minimum Brut" />
                </template>
              </Column>

              <Column :exportable="false">
                <template #body="slotProps">
                  <!--              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />-->
                  <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteSalaryLine(slotProps.data)" />
                </template>
              </Column>
            </DataTable>

            <Toolbar class="mt-2 p-2">
              <template #start>
                <Button label="Ajouter une ligne" icon="pi pi-plus" class="p-button-success mr-2 w-full" @click="addSalaryLine" />
                <Button label="Supprimer" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedSalaryLines || !selectedSalaryLines.length" />
              </template>
            </Toolbar>
          </div>
        </div>

        <div v-if="hasAccess($route.name, true)" class="float-button">
          <Button :label="$t('admin.params.company.registerButton')" type="submit" class="w-full p-3"></Button>
        </div>

      </form>
    </div>
    <Dialog v-model:visible="deleteSalaryLineDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="salaryLine">Etes vous sur(e) de vouloir supprimer <b>{{ salaryLine.position }}</b> position ?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteSalaryLineDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteClickedSalaryLineDialog" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteSalaryLinesDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Etes vous sur(e) de vouloir ces positions ?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteSalaryLinesDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedSalaryLines" />
      </template>
    </Dialog>
    <dialog-file-preview
      :dialog-title="$t('admin.params.rh.dialogParticularConditionsWorkContractTemplate')"
      :is-active="dialogParticularConditions"
      :file-src="particularConditionsSrc"
      :modal="dialogParticularConditionsModal"
      :closable="dialogParticularConditionsClosable"
      :close-button-label="dialogParticularConditionsCloseButtonLabel"
      :validate-button-label="dialogParticularConditionsAcceptButtonLabel"
      :close-button="dialogParticularConditionsCloseButton"
      :validate-button="dialogParticularConditionsAcceptButton"
      :file-mime-type="'application/pdf'"
      :auto-load-file="false"
      @validateFile="dialogParticularConditionsFileValidation"
      @closePreview="dialogParticularConditionsCloseFilePreview"
      @closeDialog="dialogParticularConditions = false"
    />
    <dialog-file-preview
      :dialog-title="$t('admin.params.rh.dialogGeneralConditionsWorkContractTemplate')"
      :is-active="dialogGeneralConditions"
      :file-src="generalConditionsSrc"
      :modal="dialogGeneralConditionsModal"
      :closable="dialogGeneralConditionsClosable"
      :close-button-label="dialogGeneralConditionsCloseButtonLabel"
      :validate-button-label="dialogGeneralConditionsAcceptButtonLabel"
      :close-button="dialogGeneralConditionsCloseButton"
      :validate-button="dialogGeneralConditionsAcceptButton"
      :file-mime-type="'application/pdf'"
      :auto-load-file="false"
      @validateFile="dialogGeneralConditionsFileValidation"
      @closePreview="dialogGeneralConditionsCloseFilePreview"
      @closeDialog="dialogGeneralConditions = false"
    />
    <dialog-file-preview
      :dialog-title="$t('admin.params.rh.dialogPersonalGeneralConditionsWorkContractTemplate')"
      :is-active="dialogOwnGeneralConditions"
      :file-src="ownGeneralConditionsSrc"
      :file-mime-type="'application/pdf'"
      :accept-file-type="'application/pdf'"
      :auto-load-file="false"
      :auto-upload-file="false"
      replace-file-button
      delete-button
      @replaceFile="updateOwnGeneralConditions"
      @firstFileUpload="updateOwnGeneralConditions"
      @deleteFile="deleteOwnGeneralConditions"
      @closeDialog="dialogOwnGeneralConditions = false"
    />
    <dialog-file-preview
      :dialog-title="$t('admin.params.rh.dialogAgreementConventionWorkContractTemplate')"
      :is-active="dialogOwnAgreementConvention"
      :file-src="ownAgreementConventionSrc"
      :file-mime-type="'application/pdf'"
      :accept-file-type="'application/pdf'"
      :auto-load-file="false"
      :auto-upload-file="false"
      replace-file-button
      delete-button
      @replaceFile="updateOwnAgreementConvention"
      @firstFileUpload="updateOwnAgreementConvention"
      @deleteFile="deleteOwnAgreementConvention"
      @closeDialog="closeOwnAgreementConvention"
    />
    <dialog-preview-option-users
      :is-active="dialogPreviewOptionUsers"
      :independents="dialogPreviewOptionUsersIndependents"
      :option-name="dialogPreviewOptionUsersOptionName"
      :confirm-dialog-information="dialogPreviewOptionUsersConfirmDialogInformation"
      @disabledOption="dialogPreviewOptionUsersOnOptionDisabled"
      @closeDialog="dialogPreviewOptionUsers = false"
    />
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {mapState} from "vuex";
import Alert from "@/utils/Alert";
import DialogFilePreview from "@/components/DialogFilePreview";
import {
  disablePComponentsOfCurrentPage,
  getBase64,
  getBase64FromDownloadedFile,
  getBase64FromUploadedFile,
  hasAccess
} from "@/utils/Misc";
import DialogPreviewOptionUsers from "@/components/DialogPreviewOptionUsers";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "RH",
  components: {DialogPreviewOptionUsers, DialogFilePreview },
  data() {
    return {
      hasAccess: hasAccess,
      rhCreditCardOption: false,
      rhCreditCardFees: "",
      rhRestaurantsTicketsOption: false,
      rhRestaurantsTicketsAmount: 0,
      rhRestaurantTicketsSalaryRate: 0,
      rhRestaurantTicketsEmployerRate: 0,
      rhRenunciationLetter: false,

      // on basis everything is false
      rhTemplateWorkContractParticularsConditions: null,
      rhTemplateWorkContractGeneralConditions: null,
      rhTemplateWorkContractOwnGeneralConditions: null,
      // save the id but show the file as input
      rhOwnGeneralConditionsTemplateId: '',
      rhAgreementConventionId: '',
      rhAgreementConvention: null,
      rhUploadedDocument: null,
      rhUploadedAgreementConvention: null,

      rhRetirement: null,
      rhForesight: null,

      dialogPreviewOptionUsers: false,
      dialogPreviewOptionUsersIndependents: [],
      dialogPreviewOptionUsersOptionName: '',
      dialogPreviewOptionUsersConfirmDialogInformation: '',
      dialogPreviewOptionUsersOnOptionDisabled: () => {},

      dialogParticularConditionsUpdateKey: 0,
      dialogParticularConditions: false,
      dialogParticularConditionsModal: false,
      dialogParticularConditionsClosable: true,
      dialogParticularConditionsCloseButtonLabel: '',
      dialogParticularConditionsAcceptButtonLabel: '',
      dialogParticularConditionsCloseButton: false,
      dialogParticularConditionsAcceptButton: false,
      dialogParticularConditionsFileValidation: () => {},
      dialogParticularConditionsCloseFilePreview: () => {},

      dialogGeneralConditions: false,
      dialogGeneralConditionsModal: false,
      dialogGeneralConditionsClosable: true,
      dialogGeneralConditionsCloseButtonLabel: '',
      dialogGeneralConditionsAcceptButtonLabel: '',
      dialogGeneralConditionsCloseButton: false,
      dialogGeneralConditionsAcceptButton: false,
      dialogGeneralConditionsFileValidation: () => {},
      dialogGeneralConditionsCloseFilePreview: () => {},

      dialogOwnGeneralConditions: false,
      dialogOwnGeneralConditionsReplaceButtonLabel: '',
      dialogOwnGeneralConditionsReplaceButton: false,
      dialogOwnGeneralConditionsReplaceFile: () => {},

      dialogOwnAgreementConvention: false,
      dialogOwnAgreementConventionReplaceButtonLabel: '',
      dialogOwnAgreementConventionReplaceButton: false,
      dialogOwnAgreementConventionReplaceFile: () => {},

      particularConditionsSrc: null,
      generalConditionsSrc: null,
      ownGeneralConditionsSrc: null,
      ownAgreementConventionSrc: null,

      deleteSalaryLineDialog: false,
      deleteSalaryLinesDialog: false,

      selectedSalaryLines: [],
      salaryLastIdAvailable: null,
      salaryLine: null,
      salaryGrid: [
        // { id: 1, position: '1.1', coefficient: 95, minimumBrutSalary: 1983.60 },
        // { id: 2, position: '1.2', coefficient: 100, minimumBrutSalary: 2088.00 },
        // { id: 3, position: '2.1', coefficient: 105, minimumBrutSalary: 2186.10 },
        // { id: 4, position: '2.1', coefficient: 115, minimumBrutSalary: 2394.30 },
        // { id: 5, position: '2.2', coefficient: 130, minimumBrutSalary: 2706.60 },
        // { id: 6, position: '2.3', coefficient: 150, minimumBrutSalary: 3123.00 },
        // { id: 7, position: '3.1', coefficient: 170, minimumBrutSalary: 3490.10 },
        // { id: 8, position: '3.2', coefficient: 210, minimumBrutSalary: 4311.30 },
        // { id: 9, position: '3.3', coefficient: 270, minimumBrutSalary: 5549.10 }
      ],

      submitted: false
    }
  },
  async created() {
    // get only company settings ?
    await this.$store.dispatch('misc/getAllSettings', {})
    await this.initializeFields()
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings.RH,
      companySettings: state => state.misc.settings.Entreprise,
      constants: state => state.constants
    })
  },
  validations() {
    return {
      // todo: later on describe the validation precisely, if so don't forget "*" after each require fields
    }
  },
  methods: {
    async initializeFields() {
      // init temp ids for salary line, must have this to select line properly && save the last id not set
      let id = 1
      this.salaryGrid = this.settings.syntecSalaryGrid.map(salaryLine => {
        const salaryLineWithId = {...salaryLine, id}
        ++id
        return salaryLineWithId
      })
      this.salaryLastIdAvailable = id

      this.rhCreditCardOption = this.settings.carteBancaire.value
      this.rhCreditCardFees = parseInt(this.settings.carteBancaire.frais)

      this.rhRestaurantsTicketsOption = this.settings.ticketsRestaurant.value
      this.rhRestaurantsTicketsAmount = this.settings.ticketsRestaurant.amount
      this.rhRestaurantTicketsSalaryRate = this.settings.ticketsRestaurant.salaryRate * 100
      this.rhRestaurantTicketsEmployerRate = 100 - (this.settings.ticketsRestaurant.salaryRate * 100)
      this.rhRenunciationLetter = this.settings.ticketsRestaurant.lettreRenonciation

      this.rhTemplateWorkContractParticularsConditions = this.settings.contratRH.conditionsParticulieresKaizen
      this.rhTemplateWorkContractGeneralConditions = this.settings.contratRH.conditionsGeneralesKaizen
      // this.rhTemplateWorkContractOwnGeneralConditions = this.settings.contratRH.conditionsGeneralesCustom
      this.rhOwnGeneralConditionsTemplateId = this.settings.contratRH.conditionsGeneralesCustomId
      this.rhAgreementConventionId = this.settings.contratRH.portageSalarialMembershipAgreementId
      this.rhAgreementConvention = this.settings.contratRH.portageSalarialMembershipAgreement
      this.rhRetirement = this.settings.caisseRetraitePrevoyance.retraite
      this.rhForesight = this.settings.caisseRetraitePrevoyance.prevoyance
    },
    async handleSubmit(isFormValid) {
      this.submitted = true

      if (this.rhCreditCardOption && !this.rhCreditCardFees) {
        Alert.errorMessage(this, 'adminRhFormCreditCardFeesUnset')
        return
      }
      if (this.rhRestaurantsTicketsOption && !this.rhRestaurantsTicketsAmount) {
        Alert.errorMessage(this, 'adminRhFormRestaurantsTicketsAmountUnset')
        return
      }

      if (!this.isSalaryGridValid()) {
        Alert.errorMessage(this, 'adminRhFormSalaryGridUnvalid')
        return
      }
      // if ((this.rhTemplateWorkContractGeneralConditions && this.rhUploadedDocument === null && this.rhOwnGeneralConditionsTemplateId === '')) {
      //   Alert.errorMessage(this, 'adminRhFormUnUploadedFile')
      //   return
      // }
      // if (!this.rhUploadedAgreementConvention && this.rhUploadedDocument === null && this.rhOwnGeneralConditionsTemplateId === '') {
      //   Alert.errorMessage(this, 'adminRhFormUnUploadedFile')
      //   return
      // }
      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }
      await this.saveSettings()
    },
    isSalaryGridValid () {
      // check doublon
      let salaryIndex = 0
      let salaryCheckerIndex = 0
      for (const salary of this.salaryGrid) {
        salaryCheckerIndex = 0
        for (const salaryChecker of this.salaryGrid) {
          if (salaryIndex !== salaryCheckerIndex && (!salaryChecker.minimumBrutSalary || (salary.position === salaryChecker.position && salary.coefficient === salaryChecker.coefficient))) {
            return false
          }
          salaryCheckerIndex++
        }
        salaryIndex++
      }
      return true
    },
    getContractPayload () {
      return {
          contratRH: {
          conditionsGeneralesKaizen: this.rhTemplateWorkContractGeneralConditions,
          conditionsParticulieresKaizen: this.rhTemplateWorkContractParticularsConditions,
          conditionsGeneralesCustomId:this.rhOwnGeneralConditionsTemplateId,
          portageSalarialMembershipAgreement: this.rhAgreementConvention,
          portageSalarialMembershipAgreementId: this.rhAgreementConventionId
        }
      }
    },
    preparePayload () {
      return {
        RH: {
          carteBancaire: {
            frais: this.rhCreditCardFees,
            value: this.rhCreditCardOption
          },
          contratRH: {
            // conditionsGeneralesCustom: this.rhTemplateWorkContractOwnGeneralConditions,
            conditionsGeneralesKaizen: this.rhTemplateWorkContractGeneralConditions,
            conditionsParticulieresKaizen: this.rhTemplateWorkContractParticularsConditions,
            conditionsGeneralesCustomId: this.rhOwnGeneralConditionsTemplateId,
            portageSalarialMembershipAgreement: this.rhAgreementConvention,
            portageSalarialMembershipAgreementId: this.rhAgreementConventionId
          },
          ticketsRestaurant: {
            value: this.rhRestaurantsTicketsOption,
            amount: this.rhRestaurantsTicketsAmount,
            salaryRate: this.rhRestaurantTicketsSalaryRate / 100,
            employerRate: this.rhRestaurantTicketsEmployerRate / 100,
            lettreRenonciation: this.rhRenunciationLetter
          },
          caisseRetraitePrevoyance: {
            retraite: this.rhRetirement,
            prevoyance: this.rhForesight
          },
          syntecSalaryGrid: this.salaryGrid.map(salaryLine => {
            // eslint-disable-next-line no-unused-vars
            const { id, ...salaryLineWithoutId } = salaryLine
            return salaryLineWithoutId
          }),
        }
      }
    },
    async saveSettings () {
      try {
        const payload = this.preparePayload()
        await this.$store.dispatch('misc/updateSettings', payload)
        this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres sauvegardés', life: 3000});
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
      }
    },
    async saveSpecificSettings (payload) {
      try {
        await this.$store.dispatch('misc/updateRhSettings', payload)
        this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres sauvegardés', life: 3000});
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
      }
    },
    async saveContract () {
      await this.saveSpecificSettings(this.getContractPayload())
    },
    async onUploadOwnGeneralCondition(file) {
      this.dialogOwnGeneralConditionsReplaceButtonLabel = ''
      this.dialogOwnGeneralConditionsReplaceButton = false
      this.dialogOwnGeneralConditionsReplaceFile = () => {}
      getBase64FromUploadedFile(file, async (result, form) => {
        if (this.rhOwnGeneralConditionsTemplateId?.length > 0) {
          await this.$store.dispatch('file/deleteFile', { id: this.rhOwnGeneralConditionsTemplateId })
          this.rhOwnGeneralConditionsTemplateId = ''
        }
        const newGeneralConditions = await this.$store.dispatch('file/uploadFile', form)
        this.rhOwnGeneralConditionsTemplateId = newGeneralConditions.id

        await this.saveSpecificSettings(this.getContractPayload())
        this.ownGeneralConditionsSrc = result
      })
      this.dialogOwnGeneralConditions = true
    },
    async onUploadAgreementConvention (file) {
      this.dialogOwnAgreementConventionReplaceButtonLabel = ''
      this.dialogOwnAgreementConventionReplaceButton = false
      this.dialogOwnAgreementConventionReplaceFile = () => {}
      getBase64FromUploadedFile(file, async (result, form) => {
        if (this.rhAgreementConventionId?.length > 0) {
          await this.$store.dispatch('file/deleteFile', { id: this.rhAgreementConventionId })
          this.rhAgreementConventionId = ''
        }
        const newGeneralConditions = await this.$store.dispatch('file/uploadFile', form)
        this.rhAgreementConventionId = newGeneralConditions.id

        await this.saveSpecificSettings(this.getContractPayload())
        this.ownAgreementConventionSrc = result
      })
      this.dialogOwnAgreementConvention = true
    },
    async downloadExistingFile(file, callback) {
      file = await this.$store.dispatch('file/downloadFile', {id: file})
      getBase64FromDownloadedFile(file, (result) => {
        callback(result)
      })
    },
    async downloadOwnGeneralCondition(callback) {
      if (this.rhOwnGeneralConditionsTemplateId.toString().length > 0 && !this.rhTemplateWorkContractGeneralConditions) {
        await this.downloadExistingFile(this.rhOwnGeneralConditionsTemplateId, (result) => {
          this.ownGeneralConditionsSrc = result
          callback()
        })
      }
    },
    async openOwnGeneralCondition () {
      // open a new one
      if (this.rhOwnGeneralConditionsTemplateId === '' || !this.rhOwnGeneralConditionsTemplateId) {
        this.ownGeneralConditionsSrc = null
        this.dialogOwnGeneralConditions = true
        return
      }

      // get the actual one and put it as a source
      const result = await this.$store.dispatch('file/downloadFile', { id: this.rhOwnGeneralConditionsTemplateId })
      getBase64FromDownloadedFile(result, (result) => {
        this.ownGeneralConditionsSrc = result
        this.dialogOwnGeneralConditions = true
      })
    },
    // todo: this logic should be in the back
    async updateOwnGeneralConditions (payload) {
      if (this.rhOwnGeneralConditionsTemplateId?.length > 0) {
        await this.$store.dispatch('file/deleteFile', { id: this.rhOwnGeneralConditionsTemplateId })
        this.rhOwnGeneralConditionsTemplateId = ''
      }
      const newGeneralConditions = await this.$store.dispatch('file/uploadFile', payload.form)
      this.rhOwnGeneralConditionsTemplateId = newGeneralConditions.id
      await this.saveSpecificSettings(this.getContractPayload())
      this.resetOwnGeneralConditionDialog()
    },
    async deleteOwnGeneralConditions () {
      await this.$store.dispatch('file/deleteFile', { id: this.rhOwnGeneralConditionsTemplateId })
      this.rhOwnGeneralConditionsTemplateId = ''
      await this.saveSpecificSettings(this.getContractPayload())
      this.resetOwnGeneralConditionDialog()
    },
    resetOwnGeneralConditionDialog () {
      this.ownGeneralConditionsSrc = null
      this.dialogOwnGeneralConditions = false
    },
    async openOwnAgreement () {
      // open a new one
      if (this.rhAgreementConventionId === '' || !this.rhAgreementConventionId) {
        this.ownAgreementConventionSrc = null
        this.dialogOwnAgreementConvention = true
        return
      }

      // get the actual one and put it as a source
      const result = await this.$store.dispatch('file/downloadFile', { id: this.rhAgreementConventionId })
      getBase64FromDownloadedFile(result, (result) => {
        this.ownAgreementConventionSrc = result
        this.dialogOwnAgreementConvention = true
      })
    },
    // todo: this logic should be in the back
    async updateOwnAgreementConvention (payload) {
      if (this.rhAgreementConventionId?.length > 0) {
        await this.$store.dispatch('file/deleteFile', { id: this.rhAgreementConventionId })
        this.rhAgreementConventionId = ''
      }
      const newGeneralConditions = await this.$store.dispatch('file/uploadFile', payload.form)
      this.rhAgreementConvention = true
      this.rhAgreementConventionId = newGeneralConditions.id
      await this.saveSpecificSettings(this.getContractPayload())
      this.resetOwnAgreementConventionDialog()
    },
    async deleteOwnAgreementConvention () {
      await this.$store.dispatch('file/deleteFile', { id: this.rhAgreementConventionId })
      await this.resetOwnAgreementConvention()
      this.resetOwnAgreementConventionDialog()
    },
    async closeOwnAgreementConvention () {
      if (this.rhAgreementConvention && (this.rhAgreementConventionId === '' || !this.rhAgreementConventionId)) {
        await this.resetOwnAgreementConvention()
      }
      this.dialogOwnAgreementConvention = false
    },
    async resetOwnAgreementConvention () {
      this.rhAgreementConventionId = ''
      // in case of delete automatically reset the rhAgreementConvention to false
      this.rhAgreementConvention = false
      await this.saveSpecificSettings(this.getContractPayload())
    },
    resetOwnAgreementConventionDialog () {
      this.ownAgreementConventionSrc = null
      this.dialogOwnAgreementConvention = false
    },
    async downloadParticularConditionsTemplate(callback) {
      const particularConditionsTemplateBuffer = await this.$store.dispatch('rh/generateParticularConditionsContract')
      getBase64(particularConditionsTemplateBuffer, (result) => {
        this.particularConditionsSrc = result
        callback()
      })
    },
    downloadAndOpenParticularConditionsTemplate () {
      this.downloadParticularConditionsTemplate(() => {
        this.dialogParticularConditions = true
      })
    },
    openParticularConditionsPreview () {
      this.dialogParticularConditionsModal = false
      this.dialogParticularConditionsClosable = true
      this.dialogParticularConditionsCloseButtonLabel = ''
      this.dialogParticularConditionsAcceptButtonLabel = ''
      this.dialogParticularConditionsCloseButton = false
      this.dialogParticularConditionsAcceptButton = false
      this.dialogParticularConditionsFileValidation = () => {}
      this.dialogParticularConditionsCloseFilePreview = () => {}
      this.downloadAndOpenParticularConditionsTemplate()
    },
    async downloadGeneralConditionsTemplate(callback) {
      const generalConditionsTemplateBuffer = await this.$store.dispatch('rh/generateGeneralConditionsContract')
      getBase64(generalConditionsTemplateBuffer, (result) => {
        this.generalConditionsSrc = result
        callback()
      })
    },
    downloadAndOpenGeneralConditionsTemplate () {
      this.downloadGeneralConditionsTemplate(() => {
        this.dialogGeneralConditions = true
      })
    },
    openGeneralConditionsPreview () {
      this.dialogGeneralConditionsModal = false
      this.dialogGeneralConditionsClosable = true
      this.dialogGeneralConditionsCloseButtonLabel = ''
      this.dialogGeneralConditionsAcceptButtonLabel = ''
      this.dialogGeneralConditionsCloseButton = false
      this.dialogGeneralConditionsAcceptButton = false
      this.dialogParticularConditionsFileValidation = () => {}
      this.dialogParticularConditionsCloseFilePreview = () => {}
      this.downloadAndOpenGeneralConditionsTemplate()
    },
    openWorkContractParticularConditionsAcceptationPreview () {
      this.dialogParticularConditionsModal = true
      this.dialogParticularConditionsClosable = false
      this.dialogParticularConditionsCloseButtonLabel = 'Refuser'
      this.dialogParticularConditionsAcceptButtonLabel = 'Accepter'
      this.dialogParticularConditionsCloseButton = true
      this.dialogParticularConditionsAcceptButton = true
      this.dialogParticularConditionsFileValidation = async () => {
        await this.saveSpecificSettings(this.getContractPayload())
        this.dialogParticularConditions = false
      }
      this.dialogParticularConditionsCloseFilePreview = async () => {
        this.rhTemplateWorkContractParticularsConditions = false
        await this.saveSpecificSettings(this.getContractPayload())
        this.dialogParticularConditions = false
      }
      this.downloadAndOpenParticularConditionsTemplate()
    },
    openWorkContractGeneralConditionAcceptationPreview () {
      this.dialogGeneralConditionsModal = true
      this.dialogGeneralConditionsClosable = false
      this.dialogGeneralConditionsCloseButtonLabel = 'Refuser'
      this.dialogGeneralConditionsAcceptButtonLabel = 'Accepter'
      this.dialogGeneralConditionsCloseButton = true
      this.dialogGeneralConditionsAcceptButton = true
      this.dialogGeneralConditionsFileValidation = async () => {
        await this.saveSpecificSettings(this.getContractPayload())
        this.dialogGeneralConditions = false
      }
      this.dialogGeneralConditionsCloseFilePreview = async () => {
        this.rhTemplateWorkContractGeneralConditions = false
        await this.saveSpecificSettings(this.getContractPayload())
        this.dialogGeneralConditions = false
      }
      this.downloadAndOpenGeneralConditionsTemplate()
    },
    deleteClickedSalaryLineDialog () {
      const index = this.salaryGrid.findIndex(salaryLine => salaryLine.id === this.salaryLine.id)
      this.salaryGrid.splice(index, 1)
      this.deleteSalaryLineDialog = false
    },
    deleteSelectedSalaryLines () {
      this.selectedSalaryLines.forEach(salaryLine => {
        const index = this.salaryGrid.findIndex(salary => salary.id === salaryLine.id)
        if (index !== -1) {
          this.salaryGrid.splice(index, 1)
        }
      })
      this.selectedSalaryLines = []
      this.deleteSalaryLinesDialog = false
    },
    addSalaryLine () {
      this.salaryGrid.push({ id: this.salaryLastIdAvailable, position: '', coefficients: null, minimumBrutSalary: null })
      ++this.salaryLastIdAvailable
    },
    confirmDeleteSalaryLine (data) {
      this.salaryLine = data
      this.deleteSalaryLineDialog = true
    },
    confirmDeleteSelected () {
      this.deleteSalaryLinesDialog = true
    },
    async changeCreditCardOption () {
      if (!this.rhCreditCardOption) {
        await this.openDialogForCreditCardOption()
      }
    },
    async openDialogForCreditCardOption () {
      this.dialogPreviewOptionUsersIndependents = await this.$store.dispatch('rh/getIndependentUsingCreditCard')
      if (this.dialogPreviewOptionUsersIndependents?.length > 0) {
        this.dialogPreviewOptionUsersOptionName = 'carte bancaire'
        this.dialogPreviewOptionUsersConfirmDialogInformation = 'En validant, les portés séléctionnés ne pourront plus bénéficier du service de carte bancaire'
        this.dialogPreviewOptionUsersOnOptionDisabled = async (payload) => {
          try {
            await this.$store.dispatch('rh/updateIndependentUsingCreditCard', {
              keys: payload.keys,
              rhSettings: { carteBancaire: {
                  frais: this.rhCreditCardFees,
                  value: this.rhCreditCardOption
                }
              }
            })
            this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres carte de crédit et Portés mis à jour', life: 3000});
          } catch (e) {
            this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
          }
        }
        this.dialogPreviewOptionUsers = true
      }
    },
    async changeRestaurantsTicketsOption () {
      if (!this.rhRestaurantsTicketsOption) {
        await this.openDialogForRestaurantsTicketsOption()
      }
    },
    async openDialogForRestaurantsTicketsOption () {
      this.dialogPreviewOptionUsersIndependents = await this.$store.dispatch('rh/getIndependentUsingRestaurantsTickets')
      if (this.dialogPreviewOptionUsersIndependents?.length > 0) {
        this.dialogPreviewOptionUsersOptionName = 'tickets restaurants'
        this.dialogPreviewOptionUsersConfirmDialogInformation = 'En validant, les portés séléctionnés ne pourront plus saisir un nombre de tickets restaurants lors de la déclaration de paie'
        this.dialogPreviewOptionUsersOnOptionDisabled = async (payload) => {
          try {
            await this.$store.dispatch('rh/updateIndependentUsingRestaurantTickets', {
              keys: payload.keys,
              rhSettings: { ticketsRestaurant: {
                  amount: this.rhRestaurantsTicketsAmount,
                  lettreRenonciation: this.rhRenunciationLetter,
                  value: this.rhRestaurantsTicketsOption
                }
              }
            })
            this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres tickets restaurants et Portés mis à jour', life: 3000});
          } catch (e) {
            this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
          }
        }
        this.dialogPreviewOptionUsers = true
      }
    },
    changeTRSalaryRate (event) {
      this.rhRestaurantTicketsEmployerRate = (10000 - event.value * 100) / 100
    },
    changeTREmployerRate (event) {
      this.rhRestaurantTicketsSalaryRate = (10000 - event.value * 100) / 100
    }
  }
}
</script>

<style scoped>
</style>
