<template>
  <Dialog
    v-model:visible="dialogActive"
    :draggable="false"
    :style="{width: '1000px'}"
    :modal="true"
    :show-header="false"
    content-class="dialog-content"
  >
    <h3 class="mb-0">Les portés utilisant l'option {{ optionName }}</h3>
    <p class="mt-0 mb-0">Selectionnez les portés pour lesquels l'option doit être désactivé
      <!--      <i class="ml-1 pi pi-info-circle text-primary cursor-pointer" @click="this.showInfo = !this.showInfo" />-->
    </p>
    <!--      <p class="mt-0 mb-0 text-sm">Les paies des portes déjà séléctionnées n'ont pas encore été </p>-->
    <!--    <p v-if="showInfo" class="pay-info mt-0 text-sm">Les paies des portes non-séléctionnées correspondent aux portes ayant déjà reçu une paie pour la date choisie</p>-->
    <DataTable
        style="font-size: 0.75rem" :scrollable="true" scroll-direction="horizontal" class="p-datatable-sm"
        :value="independents" :rowHover="true" responsiveLayout="scroll" ref="dt"
        v-model:selection="independentSelected" data-key="id">
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column v-if="selectionMode" selectionMode="multiple"/>
      <Column field="first_name" header="Prénom"></Column>
      <Column field="last_name" header="Nom"></Column>
      <Column field="email" header="Email"></Column>
    </DataTable>
    <div class="flex flex-row-reverse">
      <Button label="Désactiver pour ces portés" :disabled="selectionMode ? independentSelected?.length === 0 : false" @click="confirmDisabledSelection" />
      <Button class="p-button-outlined mr-2" label="Ne rien faire" @click="confirmDisabledWithoutSelection"/>
    </div>
  </Dialog>
  <dialog-confirm
    :is-active="confirmDialog"
    :refuse-action="closeConfirmDialog"
    :validate-action="confirmDialogValidate"
    :title="confirmDialogTitle"
    :text-info="confirmDialogInfo"
    :text-question="confirmDialogQuestion"
  />
</template>

<script>
import DialogConfirm from "@/components/DialogConfirm";

export default {
  name: "DialogPreviewOptionUsers",
  components: { DialogConfirm },
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    optionName: {
      type: String,
      default: '',
      required: true
    },
    independents: {
      type: Array,
      default: () => [],
      required: true
    },
    selectionMode: {
      type: Boolean,
      default: false
    },
    confirmDialogInformation: {
      type: String,
      default: '',
    }
  },
  watch: {
    isActive (value) {
      if (value) {
        this.initIndependentSelected()
      }
      this.dialogActive = value
    }
  },
  data () {
    return {
      dialogActive: false,
      showInfo: false,

      independentSelected: [],

      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: '',
      confirmDialogValidate: () => {},
    }
  },
  created() {
    this.dialogActive = this.isActive
  },
  methods: {
    initIndependentSelected () {
      if (!this.selectionMode) {
        this.independentSelected = this.independents.map(independent => {
          return { id: independent.id }
        })
      }
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    closeConfirmDialog () {
      this.confirmDialog = false
    },
    confirmDisabledSelection () {
      this.confirmDialogValidate = () => {
        this.$emit('disabledOption', { keys: this.independentSelected.map((independent) => independent.id) })
        this.closeConfirmDialog()
        this.closeDialog()
      }
      // 'En validant, les portés séléctionnés ne pourront plus saisir un nombre de tickets restaurants lors de la déclaration de paie'
      this.confirmDialogTitle = `Désactiver l'option ${this.optionName}`
      this.confirmDialogInfo = this.confirmDialogInformation === '' ? '' : this.confirmDialogInformation
      this.confirmDialogQuestion = 'Êtes-vous sûr de vouloir désactiver cette option pour les portés séléctionnés précédemment ?'
      this.confirmDialog = true
    },
    confirmDisabledWithoutSelection () {
      this.confirmDialogValidate = () => {
        this.$emit('disabledOption', { keys: []})
        this.closeConfirmDialog()
        this.closeDialog()
      }
      // 'En validant, les portés séléctionnés ne pourront plus saisir un nombre de tickets restaurants lors de la déclaration de paie'
      this.confirmDialogTitle = `Désactiver l'option ${this.optionName} sans désactiver l'otion pour les portés`
      // todo: revoir en selection mode
      this.confirmDialogInfo = 'L\'option sera désactivé pour les nouveaux portés, mais restera active pour les portés bénéficiant déjà de l\'option'
      this.confirmDialogQuestion = 'Êtes-vous sûr de vouloir désactiver cette option pour les nouveaux portés sans la désactiver pour les portés indiqués ?'
      this.confirmDialog = true
    }
  }
}
</script>

<style>
.dialog-content {
  padding: 2rem !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
  background-color: white;
}
</style>
